@import '../utils/index.module.scss';

.pulsing-badge::before {
    content: '';
    display: block;
    position: absolute;
    top: '50%';
    left: '50%';
    right: '50%';
    bottom: '50%';
    animation: pulse 1s ease infinite;
    border-radius: 50%;
    border-style: double;
}

.pulsing-badge-primary::before {
    @extend .pulsing-badge;
    border-color: lighten($primary500, 20%) !important;
}

.pulsing-badge-destructive::before {
    @extend .pulsing-badge;
    border-color: lighten($destructive500, 20%) !important;
}

.pulsing-badge-active::before {
    @extend .pulsing-badge;
    border-color: lighten($primary600, 20%) !important;
}

.pulsing-badge-lg::before {
    @extend .pulsing-badge;
    border-width: 8px;
}

.pulsing-badge-md::before {
    @extend .pulsing-badge;
    border-width: 6px;
}

.pulsing-badge-sm::before {
    @extend .pulsing-badge;
    border-width: 6px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    60% {
        transform: scale(1.3);
        opacity: 0.4;
    }

    100% {
        transform: scale(1.4);
        opacity: 0;
    }
}