.popup-error {
    width: 550px;
    height: 50px;
    background: hsla(4, 86%, 58%, 1) 0 0 no-repeat padding-box;
    box-shadow: 0 4px 10px #00000005;
    border-radius: 17px;
    position: fixed;
    top: 10px;
    right: 10px;
    z-index: 13000;
}