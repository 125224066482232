@import '../utils/index.module.scss';

.default-pagination {
    @extend .typography-neutral--700;
    border-radius: 8px !important;
    padding: 8px, 14px, 8px, 14px;
    height: 36px !important;
    min-width: 36px !important;
}

.default-pagination:hover {
    @extend .typography-primary--500;
    background-color: $primary50;
    border-radius: 8px !important;
    padding: 8px, 14px, 8px, 14px;
    height: 36px !important;
    min-width: 36px !important;
}

.selected-pagination {
    @extend .typography-neutral--700;
    background-color: $neutral40;
    border-radius: 8px !important;
    padding: 8px, 14px, 8px, 14px;
    height: 36px !important;
    min-width: 36px !important;
}

.ellipsis {
    @extend .typography-neutral--700;
    border-radius: 8px !important;
    padding: 8px, 14px, 8px, 14px;
    height: 36px !important;
    min-width: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ellipsis:hover {
    @extend .typography-primary--500;
    background-color: $primary50;
    border-radius: 8px !important;
    padding: 8px, 14px, 8px, 14px;
    height: 36px !important;
    min-width: 36px !important;
    display: flex;
    align-items: center;
    justify-content: center;
}