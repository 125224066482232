@import '../utils/index.module.scss';

.table-head-custom {
    background-color: $neutral20;

    th:first-of-type {
        border-radius: 12px 0 0 0px;
        padding-left: 16px;
        box-shadow: inset 0px -1px 0px #F0F1F4;
    }

    th:last-child {
        border-radius: 0 12px 0px 0;
        box-shadow: inset -1px -1px 0px #F0F1F4;
        padding-right: 16px;
    }

    th {
        border: 0;
        box-shadow: inset 0px -1px 0px #F0F1F4;
    }
}

.table-head-custom-border {
    th:first-of-type {
        border-radius: 12px 0 0 0;
    }

    th:last-child {
        border-radius: 0 12px 0 0;
    }
}