@import '../utils/index.module.scss';

.company-roles-frame {
    display: flex;
    padding: 6px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    align-self: stretch;

    &--active {
        @extend .company-roles-frame;
        border: 1px solid $primary400;
        background: $primary50;
        border-radius: 8px;
    }
}